<template>
  <div id="app" data-app="true">
    <router-view/>
 </div>
</template>

<script>
import NavBar from './views/navbar.vue';
export default {
  name: "app",
  components : {
    'NavBar': NavBar
  },
  data () {
    return {
      switch1: true,
    }
  },
}
</script>

<style>
@font-face {
  font-family: 'VisbyRound';
  src: url('./assets/fonts/VisbyRoundCF-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'VisbyRound';
  src: url('./assets/fonts/VisbyRoundCF-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'VisbyRound';
  src: url('./assets/fonts/VisbyRoundCF-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'VisbyRound';
  src: url('./assets/fonts/VisbyRoundCF-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 10px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

body {
  overflow-x : hidden;
  height: 100vh;
}
.visbyRoundFont {
  font-family: 'VisbyRound', sans-serif;
}
</style>
